import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface WeddingState {
  wedding: number,
  wedding_date: string,
  wedding_confirmation_date: string,
  boy_name: string,
  boy_last: string,
  girl_name: string,
  girl_last: string,
  city: string,

  front_tittle: string,
  front_file_info: Object[],
  timeline_file_info: Object[],
  album_file_info: Object[],
  groomsman_file_info: Object[],
  bridemaids_file_info: Object[],
  people_visible: boolean,
  countdown_file: Object[],
  giftstores_file_info: Object[],

  couple_girl_phrase: string,
  couple_boy_phrase: string,
  couple_boy_file: Object[],
  couple_girl_file: Object[],
  couple_pick_file: Object[],

  welcome_file: Object[],
  welcome_tittle: string,
  welcome_text: string,
  welcome_location: string,

  ceremony_file: Object[],
  ceremony_name: string,
  ceremony_hour: string,
  ceremony_adress: string,
  ceremony_link: string,

  reception_file: Object[],
  reception_name: string,
  reception_hour: string,
  reception_adress: string,
  reception_link: string,

  gift_file: Object[],
  gift_name: string,
  gift_link: string,

  foot_file_url: string,
  foot_filename: string,
  foot_contenttype: string,
  foot_tittle: string,
  foot_text: string,

  step: number,
  template: number,
  store: number,
  previousdoc: object,
  guests: Object[],
  oncreate: boolean,
  loadingPreview: boolean,
  completeFileName: string,
  loadingComponent: boolean,
  music_yt_id: string,
  idTransaction: string,
  responseAuthorization: string,
  price_amount: string,
  show_final_assistance: boolean,
  wedding_gift_visible: boolean,
  wedding_party_visible: boolean,
  wedding_giftstore_visible: boolean,
  kids: boolean,
  open_wedding: boolean,
  toggle_drawer: boolean,
  show_complete_page: boolean,
  collaborators: Object[],
}

const initialState: WeddingState = {
  wedding: 0,
  wedding_date: "",
  wedding_confirmation_date: "",
  boy_name: "",
  boy_last: "",
  girl_name: "",
  girl_last: "",
  city: "",
  front_tittle: "",
  front_file_info: [{
    filename: "",
    url: "",
    contenttype: "",
    index: 0
  }],
  timeline_file_info: [
    {
      filename: "",
      url: "",
      contenttype: "",
      tittle: "Primer Encuentro",
      date: "01-01-2025",
      speech: "Nuestro primer encuentro fue en un proyecto de la universidad...",
      description: "Primer Encuentro"
    },
    {
      filename: "",
      url: "",
      contenttype: "",
      tittle: "Primera Cita",
      date: "02-02-2025",
      speech: "Nuestra Primera Cita fue en el campus despues del proyecto...",
      description: "Nuestra Primera Cita"
    },
    {
      filename: "",
      url: "",
      contenttype: "",
      tittle: "Propuesta de Matrimonio",
      date: "03-03-2025",
      speech: "Nuestra Propuesta De Matrimonio fue en un viaje de acampar....",
      description: "Nuestra Propuesta De Matrimonio"
    },
    {
      filename: "",
      url: "",
      contenttype: "",
      tittle: "Nuestro Compromiso",
      date: "04-04-2025",
      speech: "Nuestro Compromiso fue en un viaje con destino a...",
      description: "Nuestro Compromiso"
    },
  ],
  album_file_info: [
    {
      filename: "",
      url: "",
      contenttype: "",
    },
    {
      filename: "",
      url: "",
      contenttype: "",
    },
    {
      filename: "",
      url: "",
      contenttype: "",
    },
    {
      filename: "",
      url: "",
      contenttype: "",
    },
    {
      filename: "",
      url: "",
      contenttype: "",
    },
    {
      filename: "",
      url: "",
      contenttype: "",
    },
    {
      filename: "",
      url: "",
      contenttype: "",
    },
    {
      filename: "",
      url: "",
      contenttype: "",
    },
    {
      filename: "",
      url: "",
      contenttype: "",
    },            
  ],
  groomsman_file_info: [
    {
      filename: "",
      url: "",
      contenttype: "",
      name: "Mr. Alonso Wiles",
      relationship: "Padre de Harry"
    },
    {
      filename: "",
      url: "",
      contenttype: "",
      name: "Mr. Evon Wiles",
      relationship: "Madre de Harry"
    },
    {
      filename: "",
      url: "",
      contenttype: "",
      name: "Mr. Chris Wiles",
      relationship: "Hermano de Harry"
    },   
  ],
  bridemaids_file_info: [
    {
      filename: "",
      url: "",
      contenttype: "",
      name: "Mrs. Adina Wiles",
      relationship: "Hermana de Nancy"
    },
    {
      filename: "",
      url: "",
      contenttype: "",
      name: "Mr. Annetta Wiles",
      relationship: "Hermana de Nancy"
    },
    {
      filename: "",
      url: "",
      contenttype: "",
      name: "Mr. Ladonna Wiles",
      relationship: "Hermana de Nancy"
    },
  ],
  countdown_file: [{
    url: "",
    filename: "",
    contenttype: "",
  }],
  giftstores_file_info: [
    {
      name: 'Cemaco',
      photo: '',
      linksurl: '',
    },
    // {
    //   name: 'Siman',
    //   photo: '',
    //   linksurl: '',
    // }
  ],
  couple_girl_phrase: "",
  couple_boy_phrase: "",
  couple_boy_file: [
    {
      url: "",
      filename: "",
      contenttype: "",
    }
  ],
  couple_girl_file: [
    {
      url: "",
      filename: "",
      contenttype: "",
    }
  ],
  couple_pick_file: [
    {
      url: "",
      filename: "",
      contenttype: "",
    }
  ],

  welcome_file: [
    {
      url: "",
      filename: "",
      contenttype: "",
    }
  ],
  welcome_tittle: "",
  welcome_text: "",
  welcome_location: "",

  ceremony_file: [{
    url: "",
    filename: "",
    contenttype: "",
  }],
  ceremony_name: "Applebee's",
  ceremony_hour: "10:00",
  ceremony_adress: "256 Apay Road,Califonia Bong, London",
  ceremony_link: "",

  reception_file: [{
    url: "",
    filename: "",
    contenttype: "",
  }],
  reception_name: "Applebee's",
  reception_hour: "12:00",
  reception_adress: "256 Apay Road,Califonia Bong, London",
  reception_link: "",

  gift_file: [{
    url: "",
    filename: "",
    contenttype: "",
  }],
  gift_name: "Salon de Eventos",
  gift_link: "",

  foot_file_url: "",
  foot_filename: "",
  foot_contenttype: "",
  foot_tittle: "",
  foot_text: "",

  step: 0,
  template: 0,
  store: 0,
  previousdoc: {},
  guests: [{
    name: "",
    phone: "",
    confirmed: false,
  }],
  oncreate: false,
  loadingPreview: false,
  completeFileName: "",
  loadingComponent: false,
  people_visible: true,
  music_yt_id: "",
  idTransaction: "",
  responseAuthorization: "",
  price_amount: "0",
  show_final_assistance: false,
  wedding_gift_visible: true,
  wedding_party_visible: true,
  wedding_giftstore_visible: true,
  kids: true,
  open_wedding: true,
  toggle_drawer: false,
  show_complete_page: true,
  collaborators: [{
    name: "",
    description: "",
    url: "",
  }],
}

const formatNames = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

const weddingSlice = createSlice({
  name: 'wedding',
  initialState,
  reducers: {
    updateWeddingDAte(state, action: PayloadAction<string>) {
      state.wedding_date = action.payload
    },
    updateWeddingConfirmationDate(state, action: PayloadAction<string>) {
      state.wedding_confirmation_date = action.payload
    },
    updateBoyfrindName(state, action: PayloadAction<string>) {
      state.boy_name = action.payload
    },
    updateBoyfriendLastname(state, action: PayloadAction<string>) {
      state.boy_last = action.payload
    },
    updateGirlfriendName(state, action: PayloadAction<string>) {
      state.girl_name = action.payload
    },
    updateGirlfriendLastname(state, action: PayloadAction<string>) {
      state.girl_last = action.payload
    },
    updateWeddingCity(state, action: PayloadAction<string>) {
      state.city = action.payload
    },
    updateFrontTittle(state, action: PayloadAction<string>) {
      state.front_tittle = action.payload;
    },
    updateFrontImage(state, action: PayloadAction<any>) {
      state.front_file_info = action.payload;
    },
    updateTimeline(state, action: PayloadAction<any>) {
      state.timeline_file_info = action.payload
    },
    updateAlbumImage(state, action: PayloadAction<any>) {
      state.album_file_info = action.payload
    },
    deleteAlbumObject(state, action: PayloadAction<number>) {
      let index = action.payload
      let AlbumCopy = state.album_file_info.slice()
      AlbumCopy.splice(index, 1)
      state.album_file_info = AlbumCopy
    },
    updateGroomsman(state, action: PayloadAction<any>) {
      state.groomsman_file_info = action.payload
    },
    updateBridemaids(state, action: PayloadAction<any>) {
      state.bridemaids_file_info = action.payload
    },
    updatePeopleVisibility(state, action: PayloadAction<boolean>) {
      state.people_visible = action.payload
    },
    deleteGroomsmanObjet(state, action: PayloadAction<number>) {
      let index = action.payload
      let PeopleCopy = state.groomsman_file_info.slice()
      PeopleCopy.splice(index, 1)
      state.groomsman_file_info = PeopleCopy
    },
    deleteBridemaidsObject(state, action: PayloadAction<number>) {
      let index = action.payload
      let BridesCopy = state.bridemaids_file_info.slice()
      BridesCopy.splice(index, 1)
      state.bridemaids_file_info = BridesCopy
    },
    updateCoupleBoyfriendPhrase(state, action: PayloadAction<string>) {
      state.couple_boy_phrase = action.payload
    },
    updateCoupleGirlfriendPhrase(state, action: PayloadAction<string>) {
      state.couple_girl_phrase = action.payload
    },
    updateCountdownImage(state, action: PayloadAction<any>) {
      state.countdown_file = action.payload
    },
    updateCoupleBoyfriendImage(state, action: PayloadAction<any>) {
      state.couple_boy_file = action.payload;
    },
    updateCoupleGirlfriendImage(state, action: PayloadAction<any>) {
      state.couple_girl_file = action.payload;
    },
    updateCouplepictureimg(state, action: PayloadAction<any>) {
      state.couple_pick_file = action.payload;
    },
    updateWelcomeImage(state, action: PayloadAction<any>) {
      state.welcome_file = action.payload
    },
    updateWelcomeTitle(state, action: PayloadAction<string>) {
      state.welcome_tittle = action.payload
    },
    updateWelcomeText(state, action: PayloadAction<string>) {
      state.welcome_text = action.payload
    },
    updateWelcomeLocation(state, action: PayloadAction<string>) {
      state.welcome_location = action.payload
    },
    updateGuestObject(state, action: PayloadAction<any>) {
      state.guests = action.payload
    },
    deleteGuestObjet(state, action: PayloadAction<number>) {
      let index = action.payload
      let guestsCopy = state.guests.slice()
      guestsCopy.splice(index, 1)
      state.guests = guestsCopy
    },
    updateCeremonyImage(state, action: PayloadAction<any>) {
      state.ceremony_file = action.payload
    },
    updateCeremonyName(state, action: PayloadAction<string>) {
      state.ceremony_name = action.payload
    },
    updateCeremonyHour(state, action: PayloadAction<string>) {
      state.ceremony_hour = action.payload
    },
    updateCeremonyAdress(state, action: PayloadAction<string>) {
      state.ceremony_adress = action.payload
    },
    updateCeremonyLink(state, action: PayloadAction<string>) {
      state.ceremony_link = action.payload
    },
    updateReceptionImage(state, action: PayloadAction<any>) {
      state.reception_file = action.payload
    },
    updateReceptionName(state, action: PayloadAction<string>) {
      state.reception_name = action.payload
    },
    updateReceptionHour(state, action: PayloadAction<string>) {
      state.reception_hour = action.payload
    },
    updateReceptionAdress(state, action: PayloadAction<string>) {
      state.reception_adress = action.payload
    },
    updateReceptionLink(state, action: PayloadAction<string>) {
      state.reception_link = action.payload
    },
    updateGiftImage(state, action: PayloadAction<any>) {
      state.gift_file = action.payload
    },
    updateGiftName(state, action: PayloadAction<string>) {
      state.gift_name = action.payload
    },
    updateGiftLink(state, action: PayloadAction<string>) {
      state.gift_link = action.payload
    },
    updateGiftStoreLink(state, action: PayloadAction<any>) {
      state.giftstores_file_info = action.payload
    },
    updateFootImage(state, action: PayloadAction<any>) {
      state.foot_filename = action.payload["filename"]
      state.foot_file_url = action.payload["url"]
      state.foot_contenttype = action.payload["contenttype"]
    },
    updateFootTitle(state, action: PayloadAction<string>) {
      state.foot_tittle = action.payload
    },
    updateFootText(state, action: PayloadAction<string>) {
      state.foot_text = action.payload
    },
    upadteCoupleTemplate(state, action: PayloadAction<number>) {
      state.template = action.payload
    },
    updateOldWedding(state, action: PayloadAction<object>) {
      state.previousdoc = { ...state.previousdoc, ...action.payload }
    },
    updateOncreate(state, action: PayloadAction<boolean>) {
      state.oncreate = action.payload
    },
    updateLoadPreview(state, action: PayloadAction<any>) {
      state.loadingPreview = action.payload
    },
    updateCompleteFileName(state, action: PayloadAction<string>) {
      state.completeFileName = action.payload
    },
    loadingComponent(state, action: PayloadAction<boolean>) {
      state.loadingComponent = action.payload
    },
    setMusicId(state, action: PayloadAction<string>) {
      state.music_yt_id = action.payload;
    },
    setTransactionInfo(state, action: PayloadAction<any>) {
      state.idTransaction = action.payload["idTransaction"];
      state.responseAuthorization = action.payload["responseAuthorization"];
      state.completeFileName = action.payload["completeFileName"];
    },
    updatePrice(state, action: PayloadAction<any>) {
      state.price_amount = action.payload;
    },
    updateShowFinalAssistance(state, action: PayloadAction<boolean>) {
      state.show_final_assistance = action.payload;
    },
    updateShowWeddingGift(state, action: PayloadAction<boolean>) {
      state.wedding_gift_visible = action.payload;
    },
    updateShowWeddingParty(state, action: PayloadAction<boolean>) {
      state.wedding_party_visible = action.payload;
    },
    updateShowWeddingGiftStore(state, action: PayloadAction<boolean>) {
      state.wedding_giftstore_visible = action.payload;
    },
    updateToggleDrawer(state, action: PayloadAction<boolean>) {
      state.toggle_drawer = action.payload
    },
    restartReduxStateToDefault: () => initialState,
    updateAllowedKids(state, action: PayloadAction<boolean>) {
      state.kids = action.payload;
    },
    updateInvitationType(state, action: PayloadAction<boolean>) {
      state.open_wedding = action.payload;
    },
    updateShowCompletePage(state, action: PayloadAction<boolean>) {
      state.show_complete_page = action.payload;
    },
    updateCollaborators(state, action: PayloadAction<any>) {
      state.collaborators = action.payload;
    },
    setStoreWedding(state, action: PayloadAction<WeddingState>) {
      var basic_obj_file =
        [{
          url: "",
          filename: "",
          contenttype: "",
          index: 0
        }]
      state.wedding = action.payload.wedding
      state.wedding_date = action.payload.wedding_date
      state.boy_name = formatNames(action.payload.boy_name ? action.payload.boy_name : "")
      state.boy_last = formatNames(action.payload.boy_last ? action.payload.boy_last : "")
      state.girl_last = formatNames(action.payload.girl_last ? action.payload.girl_last : "")
      state.girl_name = formatNames(action.payload.girl_name ? action.payload.girl_name : "")
      state.city = action.payload.city

      state.front_tittle = action.payload.front_tittle
      if (state.front_file_info.length === 0) {
        state.front_file_info.push(basic_obj_file[0]);
      }
      state.front_file_info = (action.payload.front_file_info)
        ? action.payload.front_file_info : state.front_file_info

      var gift_stores_object_file = {
        linksurl: ""
      }

      if (state.giftstores_file_info.length === 0) {
        state.giftstores_file_info.push(gift_stores_object_file)
      }
      state.giftstores_file_info = (action.payload.giftstores_file_info)
        ? action.payload.giftstores_file_info : state.giftstores_file_info

      var timeline_obj_file = {
        filename: "",
        url: "",
        contenttype: "",
        index: 0,
        tittle: "",
        date: "",
        speech: ""
      }

      if (state.timeline_file_info.length === 0) {
        state.timeline_file_info.push(timeline_obj_file)
      }
      state.timeline_file_info = (action.payload.timeline_file_info)
        ? action.payload.timeline_file_info : state.timeline_file_info

      var album_obj_file = {
        filename: "",
        url: "",
        contenttype: "",
        index: 0
      };
      if (state.album_file_info.length === 0) {
        state.album_file_info.push(album_obj_file);
      }

      state.album_file_info = (action.payload.album_file_info)
        ? action.payload.album_file_info : state.album_file_info

      var people_obj_file = {
        filename: "",
        url: "",
        contenttype: "",
        index: 0,
        name: "",
        relationship: ""
      };
      if (state.groomsman_file_info.length === 0) {
        state.groomsman_file_info.push(people_obj_file);
      }
      state.groomsman_file_info = (action.payload.groomsman_file_info)
        ? action.payload.groomsman_file_info : state.groomsman_file_info

      if (state.bridemaids_file_info.length === 0) {
        state.bridemaids_file_info.push(people_obj_file)
      }
      state.bridemaids_file_info = (action.payload.bridemaids_file_info)
        ? action.payload.bridemaids_file_info : state.bridemaids_file_info

      state.countdown_file = (action.payload.countdown_file) ? action.payload.countdown_file : basic_obj_file;
      state.couple_girl_phrase = (action.payload.couple_girl_phrase) ? action.payload.couple_girl_phrase : ""
      state.couple_boy_phrase = (action.payload.couple_boy_phrase) ? action.payload.couple_boy_phrase : ""

      state.couple_boy_file = (action.payload.couple_boy_file) ? action.payload.couple_boy_file : basic_obj_file;
      state.couple_girl_file = (action.payload.couple_girl_file) ? action.payload.couple_girl_file : basic_obj_file;
      state.couple_pick_file = (action.payload.couple_pick_file) ? action.payload.couple_pick_file : basic_obj_file;

      state.welcome_file = (action.payload.welcome_file) ? action.payload.welcome_file : basic_obj_file;
      state.welcome_tittle = (action.payload.welcome_tittle) ? action.payload.welcome_tittle : ""
      state.welcome_text = (action.payload.welcome_text) ? action.payload.welcome_text : ""
      state.welcome_location = (action.payload.welcome_location) ? action.payload.welcome_location : ""

      state.ceremony_file = (action.payload.ceremony_file) ? action.payload.ceremony_file : basic_obj_file;
      state.ceremony_name = (action.payload.ceremony_name) ? action.payload.ceremony_name : ""
      state.ceremony_hour = (action.payload.ceremony_hour) ? action.payload.ceremony_hour : ""
      state.ceremony_adress = (action.payload.ceremony_adress) ? action.payload.ceremony_adress : ""
      state.ceremony_link = (action.payload.ceremony_link) ? action.payload.ceremony_link : ""

      state.reception_file = (action.payload.reception_file) ? action.payload.reception_file : basic_obj_file;
      state.reception_name = (action.payload.reception_name) ? action.payload.reception_name : ""
      state.reception_hour = (action.payload.reception_hour) ? action.payload.reception_hour : ""
      state.reception_adress = (action.payload.reception_adress) ? action.payload.reception_adress : ""
      state.reception_link = (action.payload.reception_link) ? action.payload.reception_link : ""

      state.gift_file = (action.payload.gift_file) ? action.payload.gift_file : basic_obj_file;
      state.gift_name = (action.payload.gift_name) ? action.payload.gift_name : ""
      state.gift_link = (action.payload.gift_link) ? action.payload.gift_link : ""

      state.foot_filename = (action.payload.foot_filename) ? action.payload.foot_filename : ""
      state.foot_contenttype = (action.payload.foot_contenttype) ? action.payload.foot_contenttype : ""
      state.foot_tittle = (action.payload.foot_tittle) ? action.payload.foot_tittle : ""
      state.foot_text = (action.payload.foot_text) ? action.payload.foot_text : ""
      state.wedding_confirmation_date = (action.payload.wedding_confirmation_date) ? action.payload.wedding_confirmation_date : ""

      state.previousdoc = action.payload
      state.city = action.payload.city
      state.guests = (action.payload.guests)
        ? action.payload.guests : state.guests
      state.step = action.payload.step
      state.template = action.payload.template
      state.store = action.payload.store
      state.completeFileName = (action.payload.completeFileName) ? action.payload.completeFileName : ""
      state.music_yt_id = (action.payload.music_yt_id) ? action.payload.music_yt_id : ""
      state.idTransaction = (action.payload.idTransaction) ? action.payload.idTransaction : ""
      state.responseAuthorization = (action.payload.responseAuthorization) ? action.payload.responseAuthorization : ""
      state.price_amount = (action.payload.price_amount) ? action.payload.price_amount : "0"
      state.show_final_assistance = (state.show_final_assistance) ? state.show_final_assistance : false
      state.people_visible = (action.payload.people_visible !== undefined) ? action.payload.people_visible : true
      state.wedding_gift_visible = (action.payload.wedding_gift_visible !== undefined) ? action.payload.wedding_gift_visible : true
      state.wedding_party_visible = (action.payload.wedding_party_visible !== undefined) ? action.payload.wedding_party_visible : true
      state.wedding_giftstore_visible = (action.payload.wedding_giftstore_visible !== undefined) ? action.payload.wedding_giftstore_visible : true
      state.kids = (action.payload.kids !== undefined) ? action.payload.kids : true
      state.open_wedding = (action.payload.open_wedding !== undefined) ? action.payload.open_wedding : true
      state.show_complete_page = (action.payload.show_complete_page !== undefined) ? action.payload.show_complete_page : true
      var collaborator_template_obj = {
        name: "",
        description: "",
        url: "",
      }
      state.collaborators = (action.payload.collaborators) ? action.payload.collaborators : [];
      if (state.collaborators.length === 0) {
        state.groomsman_file_info.push(collaborator_template_obj);
      }
    }
  }
})

export const {
  updateWeddingDAte,
  updateBoyfrindName,
  updateBoyfriendLastname,
  updateGirlfriendName,
  updateGirlfriendLastname,
  updateWeddingCity,
  updateFrontTittle,
  updateFrontImage,
  updateTimeline,
  updateAlbumImage,
  deleteAlbumObject,
  updateGroomsman,
  updateBridemaids,
  updatePeopleVisibility,
  deleteGroomsmanObjet,
  deleteBridemaidsObject,
  updateCountdownImage,
  updateCoupleBoyfriendPhrase,
  updateCoupleGirlfriendPhrase,
  updateCoupleBoyfriendImage,
  updateCoupleGirlfriendImage,
  updateCouplepictureimg,
  updateWelcomeImage,
  updateWelcomeTitle,
  updateWelcomeText,
  updateWelcomeLocation,
  updateGuestObject,
  deleteGuestObjet,
  updateCeremonyImage,
  updateCeremonyName,
  updateCeremonyHour,
  updateCeremonyAdress,
  updateCeremonyLink,
  updateReceptionImage,
  updateReceptionName,
  updateReceptionHour,
  updateReceptionAdress,
  updateReceptionLink,
  updateGiftImage,
  updateGiftName,
  updateGiftLink,
  updateGiftStoreLink,
  updateFootImage,
  updateFootTitle,
  updateFootText,
  upadteCoupleTemplate,
  updateOldWedding,
  setStoreWedding,
  updateLoadPreview,
  updateCompleteFileName,
  loadingComponent,
  setMusicId,
  setTransactionInfo,
  updatePrice,
  updateShowFinalAssistance,
  updateShowWeddingGift,
  updateShowWeddingParty,
  updateShowWeddingGiftStore,
  updateToggleDrawer,
  restartReduxStateToDefault,
  updateAllowedKids,
  updateInvitationType,
  updateShowCompletePage,
  updateCollaborators,
  updateWeddingConfirmationDate,
} = weddingSlice.actions
export default weddingSlice.reducer;
