import React, { useEffect } from 'react';
import Navbar from '../../components/Navbar'
import YTComponent from '../../components/ReactPlayer';
import { useAppSelector } from '../../app/hooks';
import Couple from '../../components/NewTemplateStyles/Style1/components/Couple';
import Story from '../../components/NewTemplateStyles/Style1/components/Story';
import Family from '../../components/NewTemplateStyles/Style1/components/Family';
import Gallery from '../../components/NewTemplateStyles/Style1/components/Gallery';
import Events from '../../components/NewTemplateStyles/Style1/components/Events';
import SimpleSlider from '../../components/hero';
import Rsvp from '../../components/NewTemplateStyles/Style2/components/Rsvp';
import CollaboratorsButton from '../../components/Collaborators';

const Homepage5 = ({ onPreview }) => {

  const weddingInformation = useAppSelector((state) => (state.wedding));
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className='style5_minimalist'>
      <Navbar onPreview={onPreview} />
      <SimpleSlider />
      {/* {
        (weddingInformation.music_yt_id && weddingInformation.music_yt_id !== "")
          ?
          <YTComponent url={weddingInformation.music_yt_id} autoplay={true} onPreview={onPreview}/>
          : ''
      } */}
      <Couple />
      <Story />
      <Family />
      <Gallery />
      <Rsvp />
      <Events />
      <CollaboratorsButton />
    </div>
  )
}

export default Homepage5;
