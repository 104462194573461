import React from 'react'
import { Link } from 'react-router-dom'
import couple1 from '../../images/couple/1.jpg'
import couple2 from '../../images/couple/2.jpg'
import couple3 from '../../images/couple/3.jpg'
import { useAppSelector } from '../../app/hooks'
import './style.css'

const Couple2 = () => {

  let couple2obj = useAppSelector((state) => state.wedding)

  return (
    <div id="couple" className="couple-area section-padding pb-70">
      <div className="container">
        <div className="col-l2">
          <div className="section-title text-center">
            <h2>Feliz Pareja</h2>
          </div>
        </div>
        <div className="couple-wrap">
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-6 couple-single">
              <div className="couple-wrap2">
                <div className="couple-img3">
                  <img src={(couple2obj.couple_girl_file[0].url)
                    ? couple2obj.couple_girl_file[0].url
                    : couple1}
                    alt="Girl in couple2" />
                </div>
                <div className="couple-text">
                  <div className="couple-content">
                    <h4>
                      {(couple2obj.girl_name)
                        ? couple2obj.girl_name + " " + couple2obj.girl_last
                        : 'NANCY ELIZABETH'
                      }
                    </h4>
                    <p>{(couple2obj.couple_girl_phrase)
                      ? couple2obj.couple_girl_phrase
                      : 'Hi I am Nancy Elizabeth. I am going to introduce myself.I am a professional graphic designer.'
                    }</p>
                  </div>
                  <div className="social-icon">
                    <ul className="d-flex">
                      {/* <li><Link to="/home2"><i className="fa fa-facebook"></i></Link></li>
                      <li><Link to="/home2"><i className="fa fa-twitter"></i></Link></li>
                      <li><Link to="/home2"><i className="fa fa-google-plus"></i></Link></li>
                      <li><Link to="/home2"><i className="fa fa-linkedin"></i></Link></li> */}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 couple-single">
              <div className="couple-wrap3">
                <div className="couple-img2">
                  <img src={(couple2obj.couple_pick_file[0].url)
                    ? couple2obj.couple_pick_file[0].url
                    : couple3}
                    alt="Girl and Boy couple2" />
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 couple-single md-0">
              <div className="couple-wrap2">
                <div className="couple-img3">
                  <img src={(couple2obj.couple_boy_file[0].url)
                    ? couple2obj.couple_boy_file[0].url
                    : couple2}
                    alt="Boy in couple2" />
                </div>
                <div className="couple-text">
                  <div className="couple-content">
                    <h4>{(couple2obj.boy_name)
                      ? couple2obj.boy_name + " " + couple2obj.boy_last
                      : 'HARRY WILLIAM'
                    }</h4>
                    <p>{(couple2obj.couple_boy_phrase)
                      ? couple2obj.couple_boy_phrase
                      : 'Hi I am Harry William. I am going to introduce myself.I am a professional graphic designer.'
                    }</p>
                  </div>
                  <div className="social-icon">
                    <ul className="d-flex">
                      {/* <li><Link to="/home2"><i className="fa fa-facebook"></i></Link></li>
                      <li><Link to="/home2"><i className="fa fa-twitter"></i></Link></li>
                      <li><Link to="/home2"><i className="fa fa-google-plus"></i></Link></li>
                      <li><Link to="/home2"><i className="fa fa-linkedin"></i></Link></li> */}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Couple2;
