import React from 'react';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { useAppSelector, useAppDispatch } from '../../../app/hooks';
import './style.css'
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import Paper from '@mui/material/Paper';
import { setMusicId, updateOldWedding } from '../../../features/wedding-state/wedding-state';
import './MusicPlayer.css';
import YTComponent from '../../ReactPlayer';
import Switch from '@mui/material/Switch';
import Button from '@mui/material/Button';
import docdbMethods from '../../../features/apis/docdb';
import alertMethods from '../../../features/alerts/alerts';

const successAlert = (mensaje) => {
  alertMethods.generalAlert('', 'success', mensaje, 3000, true)
}

const errorAlert = (mensaje) => {
  alertMethods.generalAlert('', 'error', mensaje, 3000, true)
}

const MusicInformation = () => {

  function createData(id, songName, author, yt_id) {
    return { id, songName, author, yt_id };
  }

  const songs = [
    "https://www.youtube.com/watch?v=snFhcHHdzT0",
    "https://www.youtube.com/watch?v=d1tNzOYlonc",
    "https://www.youtube.com/watch?v=g4M0hH1R2eU",
    "https://www.youtube.com/watch?v=2oooF0_1390",
    "https://www.youtube.com/watch?v=kPhpHvnnn0Q",
    "https://www.youtube.com/watch?v=vyto0Zhi154",
    "https://www.youtube.com/watch?v=nXTxmu5WSGA",
    "https://www.youtube.com/watch?v=qwsVGemiTpE",
  ];

  const songInformation = useAppSelector((state) => state.wedding);

  const rows = [
    createData(1, "Creo en ti", "Reik", songs[0]),
    createData(2, "Por ti me casare", "Eros Ramazzoti", songs[1]),
    createData(3, "Bésame Mucho", "Andrea Bocelli", songs[2]),
    createData(4, "Todo cambio", "Camila", songs[3]),
    createData(5, "Perfect", "Ed Sheeran", songs[4]),
    createData(6, "Marry Me", "Train", songs[5]),
    createData(7, "Open Arms", "Journey", songs[6]),
    createData(8, "Joga Bonito", "Sabino", songs[7]),
  ];

  const dispatch = useAppDispatch();

  const handleChange = (event) => {
    dispatch(setMusicId(songs[event.target.id]));
  }

  const saveSong = async () => {
    const updateObj = await docdbMethods.getDifferenceObj(songInformation, songInformation.previousdoc);
    docdbMethods.updateDocument(songInformation.wedding, updateObj).then(res => {
      if (res.status && res.status === "error") return alert(res.message);
      dispatch(updateOldWedding(updateObj))
      successAlert("Cambios salvados correctamente.")
    }).catch(error => {
      errorAlert('Error al salvar los cambios.')
    })
  }

  return (
    <div id="PersonalForm" className="personal-form-area go-personal-form-area section-padding">
      <div className="container">
        <div className="person-btn">
          <Nav tabs>
            <NavItem>
              <NavLink>
                Canciones
              </NavLink>
            </NavItem>
          </Nav>
        </div>

        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Opcion</TableCell>
                <TableCell align="right">Escuchar</TableCell>
                <TableCell align="right">Nombre</TableCell>
                <TableCell align="right">Autor</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => (
                <TableRow
                  key={row.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    <Switch
                      id={`${index}`}
                      checked={(songInformation.music_yt_id === row.yt_id)}
                      onChange={handleChange}
                    />
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <YTComponent url={row.yt_id} autoplay={1} onPreview={true} />
                  </TableCell>
                  <TableCell align="right">{row.songName}</TableCell>
                  <TableCell align="right">{row.author}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Button variant="contained" disableElevation onClick={() => saveSong()}>
          Guardar
        </Button>
      </div>
    </div>
  );
}

export default MusicInformation;
