import React, {useEffect} from 'react';
import Couple2 from '../../components/couple2';
import SimpleSlider2 from '../../components/hero2';
import Story from '../../components/story';
import Welcome from '../../components/welcome-area';
import People from '../../components/people';
import Location from '../../components/location';
import Gallery from '../../components/gallery';
import Rsvp from '../../components/rsvp';
import Gift from '../../components/gift';
import Navbar from '../../components/Navbar'
import Saveday from '../../components/countdown'
import { useAppSelector } from '../../app/hooks';
import YTComponent from '../../components/ReactPlayer';
import CollaboratorsButton from '../../components/Collaborators';


const Homepage2 = ({ onPreview }) => {
  const homeInfo2 = useAppSelector((state) => (state.wedding));
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Navbar onPreview={onPreview} />
      <SimpleSlider2 />
      {/* {
        (homeInfo2.music_yt_id && homeInfo2.music_yt_id !== "")
          ?
          <YTComponent
            url={homeInfo2.music_yt_id}
            autoplay={true} onPreview={onPreview}
          />
          : ''
      } */}
      <Saveday />
      <Couple2 />
      <Welcome />
      <Story />
      {homeInfo2.people_visible
        ? <People />
        : ''}
      <Location />
      <Gallery />
      <Rsvp />
      {
        (homeInfo2.wedding_giftstore_visible) ?
          <Gift /> : ''
      }
      <CollaboratorsButton />
    </div>
  )
}

export default Homepage2;